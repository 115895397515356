<template>
  <div class="web-account anchor" id="CardBenefits">
    <div class="web-account-tip">{{ info.tip }}</div>

    <div class="web-account-step">
      <div class="web-account-step-view">
        <img
          src="@/assets/images/web/web_icon_step1_1.png"
          class="web-account-step-view-step1-icon"
        />
        <div class="web-account-step-view-step1-title">{{ info.steps[0].title }}</div>
        <div class="web-account-step-view-text">{{ info.steps[0].subTitle }}</div>
      </div>
      <div class="web-account-step-view web-account-step-view-margin">
        <img
          src="@/assets/images/web/web_icon_step2_1.png"
          class="web-account-step-view-step2-icon"
        />
        <div class="web-account-step-view-step2-title">{{ info.steps[1].title }}</div>
        <div class="web-account-step-view-text">{{ info.steps[1].subTitle }}</div>
      </div>
      <div class="web-account-step-view web-account-step-view-margin">
        <img
          src="@/assets/images/web/web_icon_step3_1.png"
          class="web-account-step-view-step3-icon"
        />
        <div class="web-account-step-view-step3-title">{{ info.steps[2].title }}</div>
        <div class="web-account-step-view-text">{{ info.steps[2].subTitle }}</div>
      </div>
    </div>

    <div class="web-account-app">
      <div class="web-account-app-content">
        <div class="web-account-app-content-title">{{ info.appCard.title }}</div>
        <div class="web-account-app-content-line" />
        <div class="web-account-app-content-text">{{ info.appCard.infoList[0] }}</div>
        <div class="web-account-app-content-text">{{ info.appCard.infoList[1] }}</div>
        <div class="web-account-app-content-text">{{ info.appCard.infoList[2] }}</div>
        <div class="web-account-app-content-text">{{ info.appCard.infoList[3] }}</div>
        <div class="web-account-app-content-button" @click="downloadApk">
          {{ info.appCard.buttonTitle }}
        </div>
      </div>
    </div>

    <div class="web-account-card">
      <div class="web-account-card-content">
        <div class="web-account-card-content-title">{{ info.accountCard.title }}</div>
        <div class="web-account-card-content-line" />
        <div class="web-account-card-content-text">{{ info.accountCard.infoList[0] }}</div>
        <div class="web-account-card-content-text">
          {{ info.accountCard.infoList[1] }}
        </div>
        <div class="web-account-card-content-button" @click="downloadApk">
          {{ info.accountCard.buttonTitle }}
        </div>
      </div>
    </div>

    <div class="web-account-info">
      <div class="web-account-info-outview">
        <div class="web-account-info-view">
          <div class="web-account-info-view-top">
            <img
              src="@/assets/images/web/web_account_info1_1.png"
              class="web-account-info-view-top-img1"
            />
          </div>
          <div class="web-account-info-view-text">{{ info.accountList[0] }}</div>
        </div>
        <div class="web-account-info-view">
          <div class="web-account-info-view-top">
            <img
              src="@/assets/images/web/web_account_info2_1.png"
              class="web-account-info-view-top-img2"
            />
          </div>
          <div class="web-account-info-view-text">{{ info.accountList[1] }}</div>
        </div>
        <div class="web-account-info-view">
          <div class="web-account-info-view-top">
            <img
              src="@/assets/images/web/web_account_info3_1.png"
              class="web-account-info-view-top-img3"
            />
          </div>
          <div class="web-account-info-view-text">{{ info.accountList[2] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebAccount',
  props: ['lang', 'info'],
  methods: {
    downloadApk() {
      const a = document.createElement('a')
      a.href = 'https://www.letspay.cc/AppForAPK'
      a.target = '_blank'
      a.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.web-account {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-tip {
    margin-top: 54px;
    font-size: 23px;
    color: #ffffff;
    line-height: 48px;
    letter-spacing: 1px;
    text-align: center;
  }

  &-step {
    margin-top: 23px;
    width: 1300px;
    display: flex;
    justify-content: space-between;

    &-view {
      width: 398px;
      min-height: 268px;
      padding-top: 29px;
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      background: #232c54;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 22px;

      &-margin {
        margin-left: 42px;
      }

      &-step1-icon {
        width: 115px;
        height: 94px;
      }

      &-step1-title {
        margin-top: 25px;
        font-size: 26px;
        font-weight: 600;
        color: #258cfb;
        line-height: 30px;
      }

      &-step2-icon {
        width: 115px;
        height: 94px;
      }

      &-step2-title {
        margin-top: 25px;
        font-size: 26px;
        font-weight: 600;
        color: #1acbb3;
        line-height: 30px;
      }

      &-step3-icon {
        width: 115px;
        height: 94px;
      }

      &-step3-title {
        margin-top: 25px;
        font-size: 26px;
        font-weight: 600;
        color: #258cfb;
        line-height: 30px;
      }

      &-text {
        margin-top: 25px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
      }
    }
  }

  &-app {
    margin-top: 54px;
    height: 623px;
    width: 1300px;
    padding-left: 800px;
    display: flex;
    background-image: url('~@/assets/images/web/web_account_app1.png');
    background-size: cover;
    background-repeat: no-repeat;

    &-content {
      margin-top: 192px;
      margin-left: -50px;
      z-index: 99;

      &-title {
        font-size: 40px;
        font-weight: 800;
        color: #258cfb;
        line-height: 56px;
      }

      &-line {
        margin-top: 5px;
        margin-bottom: 30px;
        width: 82px;
        height: 6px;
        background: linear-gradient(129deg, #258cfb 0%, #1acbb3 100%);
      }

      &-text {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
      }

      &-button {
        margin-top: 40px;
        width: 160px;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }

  &-card {
    margin-top: 54px;
    height: 607px;
    width: 1300px;
    padding-left: 234px;
    display: flex;
    background-image: url('~@/assets/images/web/web_account_card1.png');
    background-size: cover;
    background-repeat: no-repeat;

    &-content {
      margin-top: 218px;
      width: 480px;

      &-title {
        font-size: 40px;
        font-weight: 800;
        color: #1acbb3;
        line-height: 56px;
      }

      &-line {
        margin-top: 5px;
        margin-bottom: 30px;
        width: 82px;
        height: 6px;
        background: linear-gradient(129deg, #258cfb 0%, #1acbb3 100%);
      }

      &-text {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
      }

      &-button {
        margin-top: 40px;
        width: 160px;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }

  &-info {
    margin-top: 86px;
    height: 438px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;

    &-outview {
      width: 1300px;
      display: flex;
    }

    &-view {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      opacity: 1;

      &-top {
        height: 200px;

        &-img1 {
          width: 228px;
          height: 200px;
        }

        &-img2 {
          width: 228px;
          height: 200px;
        }

        &-img3 {
          width: 228px;
          height: 200px;
        }
      }

      &-text {
        width: 314px;
        margin-top: 23px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}
</style>
