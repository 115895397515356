export const DATASOURCE = {
  TC: {
    nav: {
      list: ['首頁', '卡權益', '常見問題', '推廣權益'],
      listId: ['Home', 'CardBenefits', 'Questions', 'PromotionRights'],
    },
    header: {
      title: '國際禮品卡市場領導者',
      subTitle:
        'LETSPAY為全球用戶提供安全、便利、可靠的禮品卡服務。通過線上 App ，即可滿足線上開戶、收付款及虛擬產品購買等需求。',
    },
    account: {
      tip: '輕松註冊  全球快遞直達',
      steps: [
        {
          title: '註冊',
          subTitle: '使用常用的郵箱註冊',
        },
        {
          title: '完成實名',
          subTitle: '使用身份證件並通過實名認證',
        },
        {
          title: '卡片到家',
          subTitle: '實體卡片從香港郵寄到家',
        },
      ],
      appCard: {
        title: 'LETSPAY',
        infoList: [
          '支持全球護照/身份證件申請',
          '支持綁定在線支付工具',
          '支持快速充值',
          '支持Web3.0發展',
        ],
        buttonTitle: '開始使用',
      },
      accountCard: {
        title: 'LETSPAY實體卡',
        infoList: ['支持全球ATM取款（當地貨幣）', '支持POS機刷卡'],
        buttonTitle: '開始使用',
      },
      accountList: [
        '所有資金變動即時推送，方便用戶隨時查看',
        'TOP交易所級別的數位錢包管理系統',
        '定期接受外部安全審核，資金由持牌金融機構託管',
      ],
    },
    question: {
      title: '常见问题',
      tip: '我們會定期更新常見問題，具體請查看官方推特或客服群TELEGRAM',
      list: [
        {
          title: 'LETSPAY內申請的銀行卡，是什麼性質的卡？',
          text: '是一種預付費卡，使用者需要提前充值進卡，才可以使用，使用時，類似借記卡。預付費卡，分為記名卡（需要KYC）和不記名卡(不需要KYC)，在商城內可以自行選擇。',
        },
        {
          title: '使用者進行KYC需要哪些資料？',
          text: '根據香港特區法規要求，使用者申請卡片需要提交有效證件正反面照片和個人簽名等資訊。受聯合國制裁的國家用戶禁止註冊。',
        },
        {
          title: '卡片支援哪些應用場景？',
          text: '支援所有線下刷卡消費場景，支援POS機使用。由銀聯發行的記名預付費卡，支援綁定雲閃付，微信支付可以選擇使用雲閃付進行支付。',
        },
        {
          title: '錢包內的卡片是實體卡還是電子卡',
          text: '個人預付卡和禮品卡均是實體卡，需要填寫郵寄資訊。',
        },
        {
          title: '目前LETSPAY內的卡片面額是什麼貨幣？',
          text: '目前LETSPAY內的卡片面額為港幣HKD。在非香港地區使用時（包括刷卡消費，取現等），會轉換成當地貨幣計算，匯率為使用時的即時匯率。',
        },
      ],
      tipList: ['高度離岸隱私保護', '可開放式中臺系統', '支持合作商共同開發市場'],
    },
    plan: {
      title: 'KOL推廣計劃',
      subTitle: '加入推廣大使計劃，最高享受開卡費50%返傭！',
      list: ['推特意見領袖', '獨立博主', ' TIKTOK紅人', ' 社區達人'],
    },
    download: {
      title: '下载APP',
      subTitle: 'letspay.cc是LetsPay APP唯一下載網址，請勿通過其他非官方途徑下載APP',
    },
  },
  EN: {
    nav: {
      list: ['Home', 'Card Benefits', 'Questions', 'Promotion rights'],
      listId: ['Home', 'CardBenefits', 'Questions', 'PromotionRights'],
    },
    header: {
      title: 'International Gift Card Market Leader',
      subTitle:
        'LETSPAY provides secure, convenient, and reliable gift card services to users worldwide. Through online apps, you can meet the needs of online account opening, payment collection, and virtual product purchase.',
    },
    account: {
      tip: 'Easy registration for global express delivery',
      steps: [
        {
          title: 'Register',
          subTitle: 'Register using commonly used email addresses',
        },
        {
          title: 'Complete real name',
          subTitle: 'Use identity documents and pass real name authentication',
        },
        {
          title: 'Card Home',
          subTitle: 'Physical cards mailed from Hong Kong to home',
        },
      ],
      appCard: {
        title: 'LETSPAY',
        infoList: [
          'Support global passport/ID application',
          'Support binding to online payment tools',
          'Supports fast recharge',
          'Support for Web3.0 development',
        ],
        buttonTitle: 'Getting Started',
      },
      accountCard: {
        title: 'LETSPAY physical card',
        infoList: ['Support global ATM withdrawals (local currency)', 'Support POS card swiping'],
        buttonTitle: 'Getting Started',
      },
      accountList: [
        'Instant push of all fund changes, convenient for users to view at any time',
        'TOP exchange level digital wallet management system',
        'Regularly accept external security audit, and the funds are managed by licensed financial institutions',
      ],
    },
    question: {
      title: 'Question',
      tip: 'We will regularly update frequently asked questions. Please refer to the official Twitter or customer service group TELEGRAM for details',
      list: [
        {
          title: 'What is the nature of the bank card applied for within LETSPAY?',
          text: 'It is a prepaid card that users need to recharge in advance to use, similar to a debit card. Prepaid cards are divided into registered cards (requiring KYC) and anonymous cards (not requiring KYC), and can be chosen by oneself within the mall.',
        },
        {
          title: 'What information do users need for KYC?',
          text: 'According to the regulations of the Hong Kong Special Administrative Region, users need to submit valid photos of the front and back of their identification documents, as well as personal signatures and other information when applying for a card. Users from countries subject to United Nations sanctions are prohibited from registering.',
        },
        {
          title: 'What application scenarios does the card support?',
          text: 'Support all offline card swiping consumption scenarios and POS machine usage. A registered prepaid card issued by UnionPay that supports binding to cloud flash payment. WeChat payment can choose to use cloud flash payment for payment.',
        },
        {
          title: 'Is the card in the wallet a physical card or an electronic card？',
          text: 'Both personal prepaid cards and gift cards are physical cards, and mailing information needs to be filled out.',
        },
        {
          title: 'What currency is the current face value of the cards in LETSPAY?',
          text: 'The current face value of the cards in LETSPAY is HKD. When used outside of Hong Kong (including card swiping, withdrawal, etc.), it will be converted into local currency for calculation, and the exchange rate will be the real-time exchange rate at the time of use.',
        },
      ],
      tipList: [
        'High offshore privacy protection',
        'Open middle platform system',
        'Support partners to jointly develop the market',
      ],
    },
    plan: {
      title: 'KOL Promotion Plan',
      subTitle:
        'Join the promotion ambassador program and enjoy up to 50% of the card opening fee as a rebate!',
      list: [
        'Twitter Opinion Leader',
        'Independent blogger',
        'TIKTOK Red Man',
        'Community influencers',
      ],
      tip: 'For all KOLs that have passed the review, the card opening rebate ratio will be increased from the default 20% to 50%. After successful promotion, you can check the corresponding commission the next day.',
      extraTip:
        '*Promotion ambassadors are required to comply with the laws of their respective countries and are not allowed to engage in illegal promotion. If any violations are found, their accounts will be immediately frozen',
    },
    download: {
      title: 'Download APP',
      subTitle:
        'letspay.cc is the only download website for the LetsPay app. Please do not download the app through other unofficial channels',
    },
  },
}
