<template>
  <div class="mobile-nav">
    <div class="mobile-nav-logo">
      <img src="@/assets/images/mobile/mobile_logo_1.png" class="mobile-nav-logo-img" />
    </div>
    <div class="mobile-nav-menu" @click="langSelectClick">
      <img src="@/assets/images/mobile/mobile_menu_1.png" class="mobile-nav-menu-img" />
    </div>
    <div v-if="langSelectShow" class="mobile-nav-tab">
      <div
        v-for="(item, index) in info.list"
        :key="index"
        @click="navPress(index)"
        class="mobile-nav-tab-item"
        :style="{
          borderTopWidth: index === 0 ? 0 : '1px',
          color: activeMenuIndex === index ? '#258CFB' : '#ffffff',
        }"
      >
        <a :href="'#' + info?.listId?.[index]">{{ item }}</a>
      </div>
      <div class="mobile-nav-tab-item" @click="langTrigger">
        {{ lang === 'TC' ? 'English' : '繁体中文' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileNav',
  props: ['lang', 'info', 'activeMenuIndex'],
  data() {
    return {
      langInfo: {
        textCN: '简体中文',
        textTC: '繁体中文',
        textEN: 'English',
        iconCN: require('@/assets/images/web/web_nav_lang_tc.png'),
        iconTC: require('@/assets/images/web/web_nav_lang_tc.png'),
        iconEN: require('@/assets/images/web/web_nav_lang_tc.png'),
      },
      langSelectShow: false,
    }
  },
  methods: {
    navPress(index) {
      this.langSelectClick()
      this.$emit('menuClick', index)
    },
    langSelectClick() {
      this.langSelectShow = !this.langSelectShow
    },
    langTrigger() {
      this.langSelectShow = false
      const language = this.lang === 'TC' ? 'EN' : 'TC'
      this.$emit('langTrigger', language)
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-nav {
  position: relative;
  width: 100vw;
  min-height: 49px;
  max-height: 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  background-color: #ffffff;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
  }

  &-logo {
    margin-left: 54px;
    display: flex;
    align-items: center;

    &-img {
      width: 43px;
      height: 35px;
    }
  }

  &-menu {
    margin-right: 30px;

    &-img {
      width: 16px;
      height: 13px;
    }
  }

  &-tab {
    position: absolute;
    right: 22px;
    top: 49px;

    &-item {
      min-width: 122px;
      height: 40px;
      padding-left: 14px;
      padding-right: 14px;
      display: flex;
      align-items: center;
      background: #1559cf;
      border-top: 1px solid #ffffff;

      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      line-height: 22px;
    }

    &-line {
      height: 1px;
      background: #ffffff;
    }
  }
}
</style>
