<template>
  <div class="web-question anchor" id="Questions">
    <div class="web-question-outview">
      <div class="web-question-info">
        <div class="web-question-info-left">
          <div class="web-question-info-left-title">{{ info.title }}</div>
          <div class="web-question-info-left-line" />
        </div>

        <div class="web-question-info-right">
          <div class="web-question-info-right-title">
            {{ info.tip }}
            <a class="web-footer-view" target="_blank" :href="'https://t.me/LetsPayhk'">
              <img
                src="@/assets/images/web/web_question_telegram1.png"
                class="web-question-info-right-icon"
              />
            </a>
            <a target="_blank" :href="'https://x.com/letspayhk'">
              <img
                src="@/assets/images/web/web_question_twitter1.png"
                class="web-question-info-right-icon"
              />
            </a>
          </div>

          <div class="web-question-info-right-item">
            <div class="web-question-info-right-item-title" @click="changeOpenedIndex(0)">
              {{ info.list[0].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                class="web-question-info-right-item-arrow"
              />
            </div>
            <div class="web-question-info-right-item-content" v-if="openedIndex === 0">
              {{ info.list[0].text }}
            </div>
          </div>
          <div class="web-question-info-right-item">
            <div class="web-question-info-right-item-title" @click="changeOpenedIndex(1)">
              {{ info.list[1].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                class="web-question-info-right-item-arrow"
              />
            </div>
            <div class="web-question-info-right-item-content" v-if="openedIndex === 1">
              {{ info.list[1].text }}
            </div>
          </div>
          <div class="web-question-info-right-item">
            <div class="web-question-info-right-item-title" @click="changeOpenedIndex(2)">
              {{ info.list[2].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                class="web-question-info-right-item-arrow"
              />
            </div>
            <div class="web-question-info-right-item-content" v-if="openedIndex === 2">
              {{ info.list[2].text }}
            </div>
          </div>
          <div class="web-question-info-right-item">
            <div class="web-question-info-right-item-title" @click="changeOpenedIndex(3)">
              {{ info.list[3].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                class="web-question-info-right-item-arrow"
              />
            </div>
            <div class="web-question-info-right-item-content" v-if="openedIndex === 3">
              {{ info.list[3].text }}
            </div>
          </div>
          <div class="web-question-info-right-item">
            <div class="web-question-info-right-item-title" @click="changeOpenedIndex(4)">
              {{ info.list[4].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                class="web-question-info-right-item-arrow"
              />
            </div>
            <div class="web-question-info-right-item-content" v-if="openedIndex === 4">
              {{ info.list[4].text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="web-question-tip">
      <div
        class="web-question-tip-item"
        :style="{
          marginLeft: '-10px',
          backgroundImage: 'url(' + require('@/assets/images/web/web_question_tip_bg1_1.png') + ')',
        }"
      >
        <div class="web-question-tip-item-mask">
          {{ info.tipList[0] }}
        </div>
      </div>
      <div
        class="web-question-tip-item"
        :style="{
          backgroundImage: 'url(' + require('@/assets/images/web/web_question_tip_bg2_1.png') + ')',
        }"
      >
        <div class="web-question-tip-item-mask">{{ info.tipList[1] }}</div>
      </div>
      <div
        class="web-question-tip-item"
        :style="{
          marginRight: '-10px',
          backgroundImage: 'url(' + require('@/assets/images/web/web_question_tip_bg3_1.png') + ')',
        }"
      >
        <div class="web-question-tip-item-mask">{{ info.tipList[2] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebQuestion',
  props: ['lang', 'info'],
  data() {
    return {
      openedIndex: 0,
    }
  },
  methods: {
    changeOpenedIndex(index) {
      this.openedIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
.web-question {
  position: relative;
  width: 100%;
  min-height: 665px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  &-outview {
    width: 1300px;
    z-index: 999;
  }

  &-info {
    min-height: 665px;
    padding-top: 59px;
    display: flex;

    &-left {
      margin-top: 56px;

      &-title {
        font-size: 40px;
        font-weight: 800;
        color: #258cfb;
        line-height: 56px;
      }

      &-line {
        margin-top: 7px;
        width: 82px;
        height: 6px;
        background: linear-gradient(129deg, #258cfb 0%, #1acbb3 100%);
      }
    }

    &-right {
      margin-left: 129px;
      width: 873px;

      &-title {
        padding-bottom: 7px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        display: flex;
        align-items: center;
      }

      &-icon {
        margin-left: 15px;
        widows: 24px;
        height: 24px;
      }

      &-item {
        padding-top: 28px;
        padding-bottom: 28px;
        border-top: 1px solid #cbcbcb;

        &-title {
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
          cursor: pointer;
        }

        &-arrow {
          margin-left: 10px;
          width: 21px;
          height: 11px;
        }

        &-content {
          padding-top: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }
    }
  }

  &-tip {
    margin-top: 46px;
    width: 100%;
    display: flex;
    height: 553px;
    overflow: hidden;

    &-item {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-repeat: no-repeat;

      &-mask {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 65px;
        padding-right: 65px;
        font-size: 28px;
        font-weight: 500;
        color: #ffffff;
        line-height: 48px;
        text-align: center;

        &:hover {
          background: rgba(37, 140, 251, 0.43);
          color: #333333;
        }
      }
    }
  }
}
</style>
