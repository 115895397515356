<template>
  <div class="mobile-home">
    <MobileNav
      :lang="lang"
      :info="dataSource.nav"
      @langTrigger="langTrigger"
      :activeMenuIndex="activeMenuIndex"
      @menuClick="menuClick"
    />
    <div class="mobile-home-container">
      <MobileHeader :lang="lang" :info="dataSource.header" />
      <MobileAccount :lang="lang" :info="dataSource.account" />
      <MobileQuestion :lang="lang" :info="dataSource.question" />
      <MobilePlan :lang="lang" :info="dataSource.plan" />
      <MobileFooter :lang="lang" />
    </div>
  </div>
</template>

<script>
import { DATASOURCE } from '@/i18n'
import MobileNav from '@/components/mobile/MobileNav.vue'
import MobileHeader from '@/components/mobile/MobileHeader.vue'
import MobileAccount from '@/components/mobile/MobileAccount.vue'
import MobileQuestion from '@/components/mobile/MobileQuestion.vue'
import MobilePlan from '@/components/mobile/MobilePlan.vue'
import MobileFooter from '@/components/mobile/MobileFooter.vue'

export default {
  name: 'MobileHome',

  components: {
    MobileNav,
    MobileHeader,
    MobileAccount,
    MobileQuestion,
    MobilePlan,
    MobileFooter,
  },

  data() {
    return {
      lang: 'TC',
      dataSource: {},
      container: null,
      activeMenuIndex: 0,
    }
  },
  created() {
    this.lang = localStorage.getItem('language') || 'TC'
    this.dataSource = DATASOURCE?.[this.lang] || {}
  },
  mounted() {
    this.container = document.querySelectorAll('.mobile-home-container')[0]
    this.container.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.container.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    langTrigger(lang) {
      this.lang = lang
      this.dataSource = DATASOURCE?.[lang] || {}
      localStorage.setItem('language', lang)
    },

    menuClick(index) {
      setTimeout(() => {
        this.activeMenuIndex = index
      }, 16)
    },

    handleScroll() {
      const anchorList = document.querySelectorAll('.anchor')
      const anchorPositions = Array.from(anchorList).map((anchor) => ({
        id: anchor.id,
        top: anchor.getBoundingClientRect().top + (this.container?.scrollTop || 0) - 82, // 计算距离窗口顶部的距离
      }))
      const scrollTop = this.container?.scrollTop || 0 // 获取当前滚动位置
      for (let i = anchorPositions.length - 1; i >= 0; i--) {
        if (scrollTop >= anchorPositions[i].top) {
          // 判断哪个锚点到了窗口顶部
          this.activeMenuIndex = i
          // 处理当前活动的锚点
          break
        }
      }
    },
  },
}
</script>

<style lang="scss">
.mobile-home {
  max-width: 100vw;
  min-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #0a0925;

  &-container {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
</style>
