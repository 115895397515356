<template>
  <div class="mobile-question anchor" id="Questions">
    <div class="mobile-question-outview">
      <div class="mobile-question-info">
        <div class="mobile-question-info-left">
          <div class="mobile-question-info-left-title">{{ info.title }}</div>
          <div class="mobile-question-info-left-line" />
        </div>

        <div class="mobile-question-info-right">
          <div class="mobile-question-info-right-title">
            {{ info.tip }}
            <div class="mobile-question-info-right-iconview">
              <a target="_blank" :href="'https://t.me/LetsPayhk'">
                <img
                  src="@/assets/images/web/web_question_telegram1.png"
                  class="mobile-question-info-right-icon"
                />
              </a>
              <a target="_blank" :href="'https://x.com/letspayhk'">
                <img
                  src="@/assets/images/web/web_question_twitter1.png"
                  class="mobile-question-info-right-icon"
                />
              </a>
            </div>
          </div>

          <div class="mobile-question-info-right-item">
            <div class="mobile-question-info-right-item-title" @click="changeOpenedIndex(0)">
              {{ info.list[0].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                :class="
                  openedIndex === 0
                    ? 'mobile-question-info-right-item-arrow'
                    : 'mobile-question-info-right-item-arrow-active'
                "
              />
            </div>
            <div class="mobile-question-info-right-item-content" v-if="openedIndex === 0">
              {{ info.list[0].text }}
            </div>
          </div>
          <div class="mobile-question-info-right-item">
            <div class="mobile-question-info-right-item-title" @click="changeOpenedIndex(1)">
              {{ info.list[1].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                :class="
                  openedIndex === 1
                    ? 'mobile-question-info-right-item-arrow'
                    : 'mobile-question-info-right-item-arrow-active'
                "
              />
            </div>
            <div class="mobile-question-info-right-item-content" v-if="openedIndex === 1">
              {{ info.list[1].text }}
            </div>
          </div>
          <div class="mobile-question-info-right-item">
            <div class="mobile-question-info-right-item-title" @click="changeOpenedIndex(2)">
              {{ info.list[2].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                :class="
                  openedIndex === 2
                    ? 'mobile-question-info-right-item-arrow'
                    : 'mobile-question-info-right-item-arrow-active'
                "
              />
            </div>
            <div class="mobile-question-info-right-item-content" v-if="openedIndex === 2">
              {{ info.list[2].text }}
            </div>
          </div>
          <div class="mobile-question-info-right-item">
            <div class="mobile-question-info-right-item-title" @click="changeOpenedIndex(3)">
              {{ info.list[3].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                :class="
                  openedIndex === 3
                    ? 'mobile-question-info-right-item-arrow'
                    : 'mobile-question-info-right-item-arrow-active'
                "
              />
            </div>
            <div class="mobile-question-info-right-item-content" v-if="openedIndex === 3">
              {{ info.list[3].text }}
            </div>
          </div>
          <div class="mobile-question-info-right-item">
            <div class="mobile-question-info-right-item-title" @click="changeOpenedIndex(4)">
              {{ info.list[4].title }}
              <img
                src="@/assets/images/web/web_down_arrow.png"
                :class="
                  openedIndex === 4
                    ? 'mobile-question-info-right-item-arrow'
                    : 'mobile-question-info-right-item-arrow-active'
                "
              />
            </div>
            <div class="mobile-question-info-right-item-content" v-if="openedIndex === 4">
              {{ info.list[4].text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-question-tip">
      <div
        class="mobile-question-tip-item"
        :style="{
          backgroundImage: 'url(' + require('@/assets/images/web/web_question_tip_bg1_1.png') + ')',
        }"
      >
        <div class="mobile-question-tip-item-text">
          {{ info.tipList[0] }}
        </div>
      </div>
      <div
        class="mobile-question-tip-item"
        :style="{
          backgroundImage: 'url(' + require('@/assets/images/web/web_question_tip_bg2_1.png') + ')',
        }"
      >
        <div class="mobile-question-tip-item-text">
          {{ info.tipList[1] }}
        </div>
      </div>
      <div
        class="mobile-question-tip-item"
        :style="{
          backgroundImage: 'url(' + require('@/assets/images/web/web_question_tip_bg3_1.png') + ')',
        }"
      >
        <div class="mobile-question-tip-item-text">
          {{ info.tipList[2] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileQuestion',
  props: ['lang', 'info'],
  data() {
    return {
      openedIndex: 0,
    }
  },
  methods: {
    changeOpenedIndex(index) {
      this.openedIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-question {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  &-outview {
    padding-top: 23px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &-info {
    display: flex;
    flex-direction: column;

    &-left {
      &-title {
        font-size: 20px;
        font-weight: 800;
        color: #258cfb;
        line-height: 28px;
      }

      &-line {
        width: 82px;
        height: 4px;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
      }
    }

    &-right {
      &-title {
        margin-top: 13px;
        padding-bottom: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #888888;
        line-height: 24px;
      }

      &-iconview {
        margin-top: 4px;
        display: flex;
      }

      &-icon {
        margin-right: 13px;
        widows: 19px;
        height: 19px;
      }

      &-item {
        padding-top: 14px;
        padding-bottom: 15px;
        border-top: 1px solid rgba(221, 220, 220, 0.61);

        &-title {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }

        &-arrow {
          margin-left: 8px;
          width: 17px;
          height: 9px;
        }

        &-arrow-active {
          margin-left: 8px;
          width: 17px;
          height: 9px;
          transform: rotateZ(180deg);
        }

        &-content {
          padding-top: 12px;
          font-size: 12px;
          font-weight: 400;
          color: #888888;
          line-height: 23px;
        }
      }
    }
  }

  &-tip {
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-item {
      width: 100vw;
      height: 277px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-repeat: no-repeat;

      &-text {
        width: 269px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
</style>
