<template>
  <div class="web-header anchor" id="Home">
    <div class="web-header-content">
      <div class="web-header-content-title">
        {{ info.title }}
        <div class="web-header-content-title-line" />
      </div>
      <div class="web-header-content-sub-title">
        {{ info.subTitle }}
      </div>
      <Download />
      <div class="web-header-content-pay">
        <img src="@/assets/images/web/web_icon_zhifu_visa.png" class="web-header-content-pay-img" />
        <img
          src="@/assets/images/web/web_icon_zhifu_master.png"
          class="web-header-content-pay-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Download from './Download'

export default {
  name: 'WebHeader',
  props: ['lang', 'info'],
  components: { Download },
}
</script>

<style lang="scss" scoped>
.web-header {
  height: 826px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url('~@/assets/images/web/web_header_bg1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &-content {
    width: 1300px;
    padding-top: 172px;

    &-title {
      width: 727px;
      font-size: 62px;
      font-weight: bold;
      line-height: 87px;
      letter-spacing: 4.66px;
      color: #ffffff;

      &-line {
        margin-left: 320px;
        margin-top: -12px;
        width: 331px;
        height: 13px;
        background: linear-gradient(270deg, #3090f9 0%, rgba(26, 203, 179, 0) 100%);
        border-radius: 2px;
      }
    }

    &-sub-title {
      margin-top: 20px;
      width: 680px;
      color: #ffffff;
      font-size: 20px;
      line-height: 48px;
    }

    &-pay {
      &-img {
        margin-top: 29px;
        margin-right: 11px;
        height: 33px;
      }
    }
  }
}
</style>
