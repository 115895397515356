<template>
  <div class="mobile-account anchor" id="CardBenefits">
    <div class="mobile-account-tip">{{ info.tip }}</div>

    <div class="mobile-account-step">
      <div class="mobile-account-step-view">
        <img
          src="@/assets/images/web/web_icon_step1_1.png"
          class="mobile-account-step-view-step1-icon"
        />
        <div class="mobile-account-step-view-step1-title">{{ info.steps[0].title }}</div>
        <div class="mobile-account-step-view-text">{{ info.steps[0].subTitle }}</div>
      </div>
      <div class="mobile-account-step-view mobile-account-step-view-margin">
        <img
          src="@/assets/images/web/web_icon_step2_1.png"
          class="mobile-account-step-view-step2-icon"
        />
        <div class="mobile-account-step-view-step2-title">{{ info.steps[1].title }}</div>
        <div class="mobile-account-step-view-text">{{ info.steps[1].subTitle }}</div>
      </div>
      <div class="mobile-account-step-view mobile-account-step-view-margin">
        <img
          src="@/assets/images/web/web_icon_step3_1.png"
          class="mobile-account-step-view-step3-icon"
        />
        <div class="mobile-account-step-view-step3-title">{{ info.steps[2].title }}</div>
        <div class="mobile-account-step-view-text">{{ info.steps[2].subTitle }}</div>
      </div>
    </div>

    <div class="mobile-account-app">
      <div class="mobile-account-app-content">
        <div class="mobile-account-app-content-title">{{ info.appCard.title }}</div>
        <div class="mobile-account-app-content-line" />
        <div class="mobile-account-app-content-text">{{ info.appCard.infoList[0] }}</div>
        <div class="mobile-account-app-content-text">{{ info.appCard.infoList[1] }}</div>
        <div class="mobile-account-app-content-text">{{ info.appCard.infoList[2] }}</div>
        <div class="mobile-account-app-content-text">{{ info.appCard.infoList[3] }}</div>
        <div class="mobile-account-app-content-button" @click="downloadApk">
          {{ info.appCard.buttonTitle }}
        </div>
      </div>
    </div>

    <div class="mobile-account-card">
      <div class="mobile-account-card-content">
        <div class="mobile-account-card-content-title">{{ info.accountCard.title }}</div>
        <div class="mobile-account-card-content-line" />
        <div class="mobile-account-card-content-text">{{ info.accountCard.infoList[0] }}</div>
        <div class="mobile-account-card-content-text">
          {{ info.accountCard.infoList[1] }}
        </div>
        <div class="mobile-account-card-content-button" @click="downloadApk">
          {{ info.accountCard.buttonTitle }}
        </div>
      </div>
    </div>

    <div class="mobile-account-info">
      <div class="mobile-account-info-outview">
        <div class="mobile-account-info-view" :style="{ marginTop: '50px' }">
          <div class="mobile-account-info-view-top">
            <img
              src="@/assets/images/web/web_account_info1_1.png"
              class="mobile-account-info-view-top-img1"
            />
          </div>
          <div class="mobile-account-info-view-text">{{ info.accountList[0] }}</div>
        </div>
        <div class="mobile-account-info-view" :style="{ marginTop: '60px' }">
          <div class="mobile-account-info-view-text">{{ info.accountList[1] }}</div>
          <div class="mobile-account-info-view-top">
            <img
              src="@/assets/images/web/web_account_info2_1.png"
              class="mobile-account-info-view-top-img2"
            />
          </div>
        </div>
        <div class="mobile-account-info-view" :style="{ marginTop: '60px' }">
          <div class="mobile-account-info-view-top">
            <img
              src="@/assets/images/web/web_account_info3_1.png"
              class="mobile-account-info-view-top-img3"
            />
          </div>
          <div class="mobile-account-info-view-text">{{ info.accountList[2] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileAccount',
  props: ['lang', 'info'],
  methods: {
    downloadApk() {
      const a = document.createElement('a')
      a.href = 'https://www.letspay.cc/AppForAPK'
      a.target = '_blank'
      a.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-account {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-tip {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 29px;
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    line-height: 28px;
    letter-spacing: 1px;
    text-align: center;
  }

  &-step {
    margin-top: 13px;

    &-view {
      margin-left: 62px;
      margin-right: 62px;
      min-height: 193px;
      padding-top: 20px;
      padding-bottom: 13px;
      display: flex;
      align-items: center;
      flex-direction: column;
      background: #232c54;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 16px;

      &-margin {
        margin-top: 28px;
      }

      &-step1-icon {
        width: 82px;
        height: 67px;
      }

      &-step1-title {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 600;
        color: #258cfb;
        line-height: 22px;
      }

      &-step2-icon {
        width: 80px;
        height: 68px;
      }

      &-step2-title {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 600;
        color: #1acbb3;
        line-height: 22px;
      }

      &-step3-icon {
        width: 80px;
        height: 68px;
      }

      &-step3-title {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 600;
        color: #258cfb;
        line-height: 22px;
      }

      &-text {
        margin-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  &-app {
    margin-top: 41px;
    margin-left: 14px;
    margin-right: 14px;
    width: calc(100vw - 28px);
    min-height: 228px;
    padding-bottom: 10px;
    display: flex;
    background-image: url('~@/assets/images/mobile/mobile_account_app_bg_1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 12px;

    &-img {
      margin-top: -27px;
      width: 522px;
      height: 887px;
    }

    &-content {
      margin-top: 20px;
      margin-left: 190px;
      margin-right: 5px;
      z-index: 99;

      &-title {
        font-size: 20px;
        font-weight: 800;
        color: #258cfb;
        line-height: 28px;
      }

      &-line {
        margin-bottom: 10px;
        width: 82px;
        height: 4px;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
      }

      &-text {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
      }

      &-button {
        margin-top: 4px;
        min-width: 75px;
        max-width: 110px;
        height: 26px;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
        border-radius: 6px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }

  &-card {
    margin-top: 14px;
    margin-left: 14px;
    margin-right: 14px;
    width: calc(100vw - 28px);
    height: 228px;
    display: flex;
    background-image: url('~@/assets/images/mobile/mobile_account_card_bg_1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 12px;

    &-content {
      margin-top: 43px;
      margin-left: 28px;

      &-title {
        font-size: 20px;
        font-weight: 800;
        color: #1acbb3;
        line-height: 28px;
      }

      &-line {
        margin-bottom: 13px;
        width: 82px;
        height: 4px;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
      }

      &-text {
        width: 180px;
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        line-height: 23px;
      }

      &-button {
        margin-top: 6px;
        min-width: 75px;
        max-width: 110px;
        height: 26px;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
        border-radius: 6px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }

  &-info {
    margin-top: 90px;
    padding-bottom: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff;

    &-outview {
      display: flex;
      flex-direction: column;
    }

    &-view {
      padding-left: 38px;
      padding-right: 29px;
      display: flex;
      flex: 1;
      align-items: center;
      opacity: 1;

      &-top {
        &-img1 {
          margin-right: 24px;
          width: 139px;
          height: 122px;
        }

        &-img2 {
          margin-left: 16px;
          margin-right: 0px;
          width: 139px;
          height: 122px;
        }

        &-img3 {
          margin-right: 28px;
          width: 139px;
          height: 122px;
        }
      }

      &-text {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
      }
    }
  }
}
</style>
