<template>
  <div class="web-footer">
    <a class="web-footer-view" target="_blank" :href="'https://x.com/letspayhk'">
      <img src="@/assets/images/web/web_icon_twitter_1.png" class="web-footer-icon" />
      <span class="web-footer-text">Twitter</span>
    </a>
    <div class="web-footer-blank" />
    <a class="web-footer-view" target="_blank" :href="'https://t.me/LetsPayhk'">
      <img src="@/assets/images/web/web_icon_telegram_1.png" class="web-footer-icon" />
      <span class="web-footer-text">Telegram</span>
    </a>

    <div class="web-footer-right">
      <!-- <span>KRISTECH LIMITED 2022-2024</span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebFooter',
  props: ['lang'],
}
</script>

<style lang="scss" scoped>
.web-footer {
  position: relative;
  height: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a0925;

  &-view {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }

  &-blank {
    width: 224px;
  }

  &-icon {
    margin-right: 24px;
    width: 46px;
    height: 38px;
  }

  &-text {
    font-size: 23px;
    font-weight: 800;
    color: #ffffff;
    line-height: 33px;
    text-decoration: none;
  }

  &-right {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;

    & > span {
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
