<template>
  <div class="web-home">
    <WebNav
      :lang="lang"
      :info="dataSource.nav"
      @langTrigger="langTrigger"
      :activeMenuIndex="activeMenuIndex"
      @menuClick="menuClick"
    />
    <div class="web-home-container">
      <WebHeader :lang="lang" :info="dataSource.header" />
      <WebAccount :lang="lang" :info="dataSource.account" />
      <WebQuestion :lang="lang" :info="dataSource.question" />
      <WebPlan :lang="lang" :info="dataSource.plan" />
      <WebFooter :lang="lang" />
    </div>
  </div>
</template>

<script>
import WebNav from '@/components/web/WebNav'
import WebHeader from '@/components/web/WebHeader'
import WebAccount from '@/components/web/WebAccount'
import WebQuestion from '@/components/web/WebQuestion'
import WebPlan from '@/components/web/WebPlan'
import WebFooter from '@/components/web/WebFooter'
import { DATASOURCE } from '@/i18n'

export default {
  name: 'WebHome',
  components: {
    WebNav,
    WebHeader,
    WebAccount,
    WebQuestion,
    WebPlan,
    WebFooter,
  },

  data() {
    return {
      lang: 'TC',
      dataSource: {},
      container: null,
      activeMenuIndex: 0,
    }
  },
  created() {
    this.lang = localStorage.getItem('language') || 'TC'
    this.dataSource = DATASOURCE?.[this.lang] || {}
  },
  mounted() {
    this.container = document.querySelectorAll('.web-home-container')[0]
    this.container.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.container.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    langTrigger(lang) {
      this.lang = lang
      this.dataSource = DATASOURCE?.[lang] || {}
      localStorage.setItem('language', lang)
    },

    menuClick(index) {
      setTimeout(() => {
        this.activeMenuIndex = index
      }, 16)
    },

    handleScroll() {
      const anchorList = document.querySelectorAll('.anchor')
      const anchorPositions = Array.from(anchorList).map((anchor) => ({
        id: anchor.id,
        top: anchor.getBoundingClientRect().top + (this.container?.scrollTop || 0) - 82, // 计算距离窗口顶部的距离
      }))
      const scrollTop = this.container?.scrollTop || 0 // 获取当前滚动位置
      for (let i = anchorPositions.length - 1; i >= 0; i--) {
        if (scrollTop >= anchorPositions[i].top) {
          // 判断哪个锚点到了窗口顶部
          this.activeMenuIndex = i
          // 处理当前活动的锚点
          break
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.web-home {
  max-width: 100vw;
  min-width: 1300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #0a0925;

  &-container {
    width: 100%;
    overflow: scroll;
  }
}
</style>
