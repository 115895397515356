<template>
  <div class="web-plan anchor" id="PromotionRights">
    <div class="web-plan-outview">
      <img src="@/assets/images/web/web_plan_img_1.png" class="web-plan-img" />
      <div class="web-plan-right">
        <div class="web-plan-right-title">{{ info.title }}</div>
        <div class="web-plan-right-line" />
        <div class="web-plan-right-content">
          <div :style="{ width: '650px' }">
            {{ info.subTitle }}
          </div>

          <div class="web-plan-right-content-info">
            <div>
              <img src="@/assets/images/web/web_plan_icon1_1.png" />
              <span>{{ info.list[0] }}</span>
            </div>

            <div>
              <img src="@/assets/images/web/web_plan_icon2_1.png" />
              <span>{{ info.list[1] }}</span>
            </div>

            <div>
              <img src="@/assets/images/web/web_plan_icon3_1.png" />
              <span>{{ info.list[2] }}</span>
            </div>

            <div>
              <img src="@/assets/images/web/web_plan_icon4_1.png" />
              <span>{{ info.list[3] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebPlan',
  props: ['lang', 'info'],
}
</script>

<style lang="scss" scoped>
.web-plan {
  position: relative;
  min-height: 650px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;

  &-outview {
    width: 1300px;
    display: flex;
  }

  &-img {
    margin-top: 75px;
    margin-right: 30px;
    width: 643px;
    height: 509px;
  }

  &-right {
    margin-top: 104px;
    width: 100%;

    &-title {
      font-size: 40px;
      font-weight: 800;
      color: #1acbb3;
      line-height: 56px;
    }

    &-line {
      margin-top: 7px;
      width: 82px;
      height: 6px;
      background: linear-gradient(129deg, #258cfb 0%, #1acbb3 100%);
    }

    &-content {
      margin-top: 30px;
      padding-left: 10px;
      padding-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 30px;

      &-info {
        margin-top: 62px;
        margin-bottom: 100px;
        display: flex;
        justify-content: space-between;

        & > div {
          width: 140px;
          display: flex;
          flex-direction: column;
          align-items: center;

          & > img {
            margin-bottom: 20px;
            width: 43px;
            height: 43px;
          }

          & > span {
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 30px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
