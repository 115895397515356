<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import '@/assets/css/reset.css'

export default {}
</script>
