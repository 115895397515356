<template>
  <div class="web-nav">
    <div class="web-nav-logo"></div>
    <div class="web-nav-button-group">
      <div
        class="web-nav-button-item"
        v-for="(item, index) in info.list"
        :key="index"
        @click="navPress(index)"
      >
        <div
          class="web-nav-button-item-normal"
          :class="activeMenuIndex === index && 'web-nav-button-item-checked'"
        >
          <a :href="'#' + info?.listId?.[index]">{{ item }}</a>
        </div>
        <div
          class="web-nav-button-item-normal-line"
          :class="activeMenuIndex === index && 'web-nav-button-item-checked-line'"
        />
      </div>
      <div class="web-nav-lang" @click="langSelectClick">
        {{ langInfo[`text${lang}`] }}
        <img v-if="lang === 'TC'" :src="langInfo[`icon${lang}`]" class="web-nav-lang-icon" />
        <div class="web-nav-lang-arrow" />
      </div>
      <div class="web-nav-lang-select" v-if="langSelectShow">
        <div
          :class="lang === 'TC' ? 'web-nav-lang-select-text-active' : 'web-nav-lang-select-text'"
          @click="langTrigger('TC')"
        >
          繁体中文
        </div>
        <div class="web-nav-lang-select-line"></div>
        <div
          :class="lang === 'EN' ? 'web-nav-lang-select-text-active' : 'web-nav-lang-select-text'"
          @click="langTrigger('EN')"
        >
          English
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebNav',
  props: ['lang', 'info', 'activeMenuIndex'],
  data() {
    return {
      langInfo: {
        textCN: '简体中文',
        textTC: '繁体中文',
        textEN: 'English',
        iconCN: require('@/assets/images/web/web_nav_lang_tc.png'),
        iconTC: require('@/assets/images/web/web_nav_lang_tc.png'),
        iconEN: require('@/assets/images/web/web_nav_lang_tc.png'),
      },
      langSelectShow: false,
    }
  },
  methods: {
    navPress(index) {
      this.$emit('menuClick', index)
    },
    langSelectClick() {
      this.langSelectShow = !this.langSelectShow
    },
    langTrigger(language) {
      this.langSelectShow = false
      this.$emit('langTrigger', language)
    },
  },
}
</script>

<style lang="scss" scoped>
.web-nav {
  min-height: 82px;
  max-height: 82px;
  width: 1300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background-color: #0a0925;

  a {
    text-decoration: none;
    color: inherit;
  }

  &-logo {
    margin-left: 60px;
    display: flex;
    align-items: center;
  }

  &-button {
    &-group {
      padding-right: 20px;
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    &-item {
      margin-left: 45px;
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
      cursor: pointer;

      &-normal {
        padding-bottom: 6px;
        color: #ffffff;

        &-line {
          height: 5px;
          background-color: transparent;
        }
      }

      &-checked {
        color: #ffffff;

        &-line {
          background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
        }
      }
    }
  }

  &-lang {
    margin-left: 45px;
    display: flex;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    cursor: pointer;
    color: #ffffff;

    &-icon {
      margin-left: 6px;
      width: 20px;
      height: 20px;
    }

    &-arrow {
      margin-top: 8px;
      margin-left: 10px;
      width: 0;
      height: 0;
      border-top: 5px solid #ffffff;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    &-select {
      position: absolute;
      top: 56px;
      display: flex;
      flex-direction: column;
      width: 122px;
      height: 80px;
      cursor: pointer;

      &-line {
        width: 122px;
        height: 1px;
        background: #ffffff;
        z-index: 999;
      }

      &-text {
        width: 100%;
        height: 40px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 22px;
        background: #1559cf;
      }

      &-text-active {
        width: 100%;
        height: 40px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #258cfb;
        line-height: 22px;
        background: #1559cf;
      }
    }
  }
}
</style>
