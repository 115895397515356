<template>
  <div class="download-img-line" style="display: flex;flex-direction: column;">
		<div style="display: flex; flex-direction: row;">
			<div class="download-img-line-view-large" @click="downloadAppleStore">
			  <img
			    src="@/assets/images/web/web_header_apple.png"
			    alt="Apple Store"
			    class="download-img-line-view-apple"
			  />
			  <div class="download-img-line-view-large-title">
			    Download on
			    <div class="download-img-line-view-large-text">APP Store</div>
			  </div>
			</div>
			<div class="download-img-line-view-large" @click="downloadTF">
			  <img
			    src="@/assets/images/web/web_tf_logo.png"
			    alt="Apple Store"
			    class="download-img-line-view-apple"
					style="width: 1.9rem;height: 1.9rem;"
			  />
			  <div class="download-img-line-view-large-title">
			    Download on
			    <div class="download-img-line-view-large-text">TestFlight</div>
			  </div>
			</div>
		</div>
		<div style="display: flex; flex-direction: row;margin-top:15px;">
			<div class="download-img-line-view-large">
				<img
					src="@/assets/images/web/web_header_google.png"
					alt="Google Play"
					class="download-img-line-view-google"
				/>
				<div class="download-img-line-view-large-title">
					Coming Soon…
					<div class="download-img-line-view-large-text">Google Play</div>
				</div>
			</div>
			<div class="download-img-line-view-small" @click="downloadApk">
				<img src="@/assets/images/web/web_apk.png" alt="APK" class="download-img-line-view-apk" />
				<div class="download-img-line-view-large-title">
					Download the
					<div class="download-img-line-view-large-text">APK</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApk() {
      const a = document.createElement('a')
      a.href = ' https://www.letspay.cc/AppForAPK'
      a.target = '_blank'
      a.click()
    },
    downloadAppleStore() {
      const a = document.createElement('a')
      a.href = 'https://www.letspay.cc/AppForIOS'
      a.target = '_blank'
      a.click()
    },
		downloadTF() {
		  const a = document.createElement('a')
		  a.href = 'https://www.letspay.cc/AppForTF'
		  a.target = '_blank'
		  a.click()
		},
  },
}
</script>

<style lang="scss" scoped>
.download {
  &-img-line {
    display: flex;

    &-view {
      &-large {
        margin-right: 7px;
        width: 111px;
        height: 43px;
        padding: 11px 8px 11px 8px;
        display: flex;
        align-items: center;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
        border-radius: 10px;

        &-title {
          font-size: 8px;
          font-weight: 300;
          color: #ffffff;
          line-height: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &-text {
          font-size: 12px;
          font-weight: 800;
          color: #ffffff;
          line-height: 17px;
        }
      }

      &-small {
        height: 43px;
        width: 110px;
        padding: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
        border-radius: 10px;
      }

      &-apple {
        margin-right: 10px;
        width: 17px;
        height: 19px;
      }

      &-google {
        margin-right: 6px;
        width: 19px;
        height: 21px;
      }

      &-apk {
        margin-right: 4px;
        width: 18px;
        height: 21px;
      }
    }
  }
}
</style>
>
