<template>
  <div class="mobile-header anchor" id="Home">
    <div :class="lang === 'TC' ? 'mobile-header-content' : 'mobile-header-content-en'">
      <div
        :class="lang === 'TC' ? 'mobile-header-content-title' : 'mobile-header-content-en-title'"
      >
        {{ info.title }}
        <div
          class="mobile-header-content-title-line"
          :class="
            lang === 'TC'
              ? 'mobile-header-content-title-line'
              : 'mobile-header-content-en-title-line'
          "
        />
      </div>
      <div
        :class="
          lang === 'TC' ? 'mobile-header-content-sub-title' : 'mobile-header-content-en-sub-title'
        "
      >
        {{ info.subTitle }}
      </div>
      <div class="mobile-header-content-download">
        <Download />
      </div>
      <div class="mobile-header-content-pay">
        <img
          src="@/assets/images/web/web_icon_zhifu_visa.png"
          class="mobile-header-content-pay-img"
        />
        <img
          src="@/assets/images/web/web_icon_zhifu_master.png"
          class="mobile-header-content-pay-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Download from './Download'

export default {
  name: 'MobileHeader',
  props: ['lang', 'info'],
  components: { Download },
}
</script>

<style lang="scss" scoped>
.mobile-header {
  position: relative;
  height: 717px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url('~@/assets/images/mobile/mobile_header_bg_1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &-content-en {
    width: 100vw;
    padding-top: 57px;

    &-title {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 33px;
      font-weight: bold;
      color: #ffffff;
      line-height: 41px;
      text-align: center;

      &-line {
        margin-left: 40px;
        margin-right: 40px;
        height: 7px;
        background: linear-gradient(270deg, #3090f9 0%, rgba(26, 203, 179, 0) 100%);
        border-radius: 2px;
      }
    }

    &-sub-title {
      margin-top: 18px;
      margin-left: 40px;
      margin-right: 40px;
      font-size: 13px;
      font-weight: 500;
      color: #ffffff;
      line-height: 27px;
    }
  }

  &-content {
    width: 100vw;
    padding-top: 90px;

    &-download {
      margin-top: 31px;
      display: flex;
      justify-content: center;
    }

    &-title {
      font-size: 30px;
      font-weight: 800;
      color: #ffffff;
      line-height: 42px;
      letter-spacing: 2px;
      text-align: center;

      &-line {
        margin-left: 40px;
        margin-right: 40px;
        height: 7px;
        background: linear-gradient(270deg, #3090f9 0%, rgba(26, 203, 179, 0) 100%);
        border-radius: 2px;
      }
    }

    &-sub-title {
      margin-top: 18px;
      margin-left: 40px;
      margin-right: 40px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 35px;
    }

    &-pay {
      position: absolute;
      bottom: 27px;
      width: 100vw;
      display: flex;
      justify-content: center;

      &-img {
        margin-top: 29px;
        margin-right: 11px;
        height: 23px;
      }
    }
  }
}
</style>
