<template>
  <div class="mobile-footer">
    <a class="mobile-footer-view" target="_blank" :href="'https://x.com/letspayhk'">
      <img src="@/assets/images/web/web_icon_twitter_1.png" class="mobile-footer-icon" />
      <span class="mobile-footer-text">Twitter</span>
    </a>
    <a class="mobile-footer-view" target="_blank" :href="'https://t.me/LetsPayhk'">
      <img src="@/assets/images/web/web_icon_telegram_1.png" class="mobile-footer-icon" />
      <span class="mobile-footer-text">Telegram</span>
    </a>

    <div class="mobile-footer-right">
      <!-- <span>KRISTECH LIMITED 2022-2024</span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileFooter',
  props: ['lang'],
}
</script>

<style lang="scss" scoped>
.mobile-footer {
  position: relative;
  height: 57px;
  display: flex;
  padding-left: 69px;
  padding-right: 69px;
  justify-content: space-between;
  align-items: center;
  background-color: #0a0925;

  &-view {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  &-icon {
    margin-right: 12px;
    width: 23px;
    height: 19px;
  }

  &-text {
    font-size: 12px;
    font-weight: 800;
    color: #ffffff;
    line-height: 16px;
    text-decoration: none;
  }

  &-right {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    text-align: center;

    & > span {
      color: #fff;
      font-size: 10px;
    }
  }

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    user-select: none;
    -moz-user-focus: none;
    -moz-user-select: none;
  }
}
</style>
