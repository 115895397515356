<template>
  <div class="home">
    <MobileHome v-if="isMobile()" />
    <WebHome v-else />
  </div>
</template>

<script>
import MobileHome from '@/views/mobile/home.vue'
import WebHome from '@/views/web/home.vue'

export default {
  name: 'Home',

  components: {
    MobileHome,
    WebHome,
  },

  methods: {
    isMobile() {
      const flag = /(Android|iPhone|iPhone|iPod|Windows Phone)/i.test(navigator.userAgent)

      return flag
    },
  },
}
</script>
