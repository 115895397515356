var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-question anchor",attrs:{"id":"Questions"}},[_c('div',{staticClass:"mobile-question-outview"},[_c('div',{staticClass:"mobile-question-info"},[_c('div',{staticClass:"mobile-question-info-left"},[_c('div',{staticClass:"mobile-question-info-left-title"},[_vm._v(_vm._s(_vm.info.title))]),_c('div',{staticClass:"mobile-question-info-left-line"})]),_c('div',{staticClass:"mobile-question-info-right"},[_c('div',{staticClass:"mobile-question-info-right-title"},[_vm._v(" "+_vm._s(_vm.info.tip)+" "),_c('div',{staticClass:"mobile-question-info-right-iconview"},[_c('a',{attrs:{"target":"_blank","href":'https://t.me/LetsPayhk'}},[_c('img',{staticClass:"mobile-question-info-right-icon",attrs:{"src":require("@/assets/images/web/web_question_telegram1.png")}})]),_c('a',{attrs:{"target":"_blank","href":'https://x.com/letspayhk'}},[_c('img',{staticClass:"mobile-question-info-right-icon",attrs:{"src":require("@/assets/images/web/web_question_twitter1.png")}})])])]),_c('div',{staticClass:"mobile-question-info-right-item"},[_c('div',{staticClass:"mobile-question-info-right-item-title",on:{"click":function($event){return _vm.changeOpenedIndex(0)}}},[_vm._v(" "+_vm._s(_vm.info.list[0].title)+" "),_c('img',{class:_vm.openedIndex === 0
                  ? 'mobile-question-info-right-item-arrow'
                  : 'mobile-question-info-right-item-arrow-active',attrs:{"src":require("@/assets/images/web/web_down_arrow.png")}})]),(_vm.openedIndex === 0)?_c('div',{staticClass:"mobile-question-info-right-item-content"},[_vm._v(" "+_vm._s(_vm.info.list[0].text)+" ")]):_vm._e()]),_c('div',{staticClass:"mobile-question-info-right-item"},[_c('div',{staticClass:"mobile-question-info-right-item-title",on:{"click":function($event){return _vm.changeOpenedIndex(1)}}},[_vm._v(" "+_vm._s(_vm.info.list[1].title)+" "),_c('img',{class:_vm.openedIndex === 1
                  ? 'mobile-question-info-right-item-arrow'
                  : 'mobile-question-info-right-item-arrow-active',attrs:{"src":require("@/assets/images/web/web_down_arrow.png")}})]),(_vm.openedIndex === 1)?_c('div',{staticClass:"mobile-question-info-right-item-content"},[_vm._v(" "+_vm._s(_vm.info.list[1].text)+" ")]):_vm._e()]),_c('div',{staticClass:"mobile-question-info-right-item"},[_c('div',{staticClass:"mobile-question-info-right-item-title",on:{"click":function($event){return _vm.changeOpenedIndex(2)}}},[_vm._v(" "+_vm._s(_vm.info.list[2].title)+" "),_c('img',{class:_vm.openedIndex === 2
                  ? 'mobile-question-info-right-item-arrow'
                  : 'mobile-question-info-right-item-arrow-active',attrs:{"src":require("@/assets/images/web/web_down_arrow.png")}})]),(_vm.openedIndex === 2)?_c('div',{staticClass:"mobile-question-info-right-item-content"},[_vm._v(" "+_vm._s(_vm.info.list[2].text)+" ")]):_vm._e()]),_c('div',{staticClass:"mobile-question-info-right-item"},[_c('div',{staticClass:"mobile-question-info-right-item-title",on:{"click":function($event){return _vm.changeOpenedIndex(3)}}},[_vm._v(" "+_vm._s(_vm.info.list[3].title)+" "),_c('img',{class:_vm.openedIndex === 3
                  ? 'mobile-question-info-right-item-arrow'
                  : 'mobile-question-info-right-item-arrow-active',attrs:{"src":require("@/assets/images/web/web_down_arrow.png")}})]),(_vm.openedIndex === 3)?_c('div',{staticClass:"mobile-question-info-right-item-content"},[_vm._v(" "+_vm._s(_vm.info.list[3].text)+" ")]):_vm._e()]),_c('div',{staticClass:"mobile-question-info-right-item"},[_c('div',{staticClass:"mobile-question-info-right-item-title",on:{"click":function($event){return _vm.changeOpenedIndex(4)}}},[_vm._v(" "+_vm._s(_vm.info.list[4].title)+" "),_c('img',{class:_vm.openedIndex === 4
                  ? 'mobile-question-info-right-item-arrow'
                  : 'mobile-question-info-right-item-arrow-active',attrs:{"src":require("@/assets/images/web/web_down_arrow.png")}})]),(_vm.openedIndex === 4)?_c('div',{staticClass:"mobile-question-info-right-item-content"},[_vm._v(" "+_vm._s(_vm.info.list[4].text)+" ")]):_vm._e()])])])]),_c('div',{staticClass:"mobile-question-tip"},[_c('div',{staticClass:"mobile-question-tip-item",style:({
        backgroundImage: 'url(' + require('@/assets/images/web/web_question_tip_bg1_1.png') + ')',
      })},[_c('div',{staticClass:"mobile-question-tip-item-text"},[_vm._v(" "+_vm._s(_vm.info.tipList[0])+" ")])]),_c('div',{staticClass:"mobile-question-tip-item",style:({
        backgroundImage: 'url(' + require('@/assets/images/web/web_question_tip_bg2_1.png') + ')',
      })},[_c('div',{staticClass:"mobile-question-tip-item-text"},[_vm._v(" "+_vm._s(_vm.info.tipList[1])+" ")])]),_c('div',{staticClass:"mobile-question-tip-item",style:({
        backgroundImage: 'url(' + require('@/assets/images/web/web_question_tip_bg3_1.png') + ')',
      })},[_c('div',{staticClass:"mobile-question-tip-item-text"},[_vm._v(" "+_vm._s(_vm.info.tipList[2])+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }