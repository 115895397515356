<template>
  <div class="download-img-line">
    <div class="download-img-line-view-large" @click="downloadAppleStore">
      <img
        src="@/assets/images/web/web_header_apple.png"
        alt="Apple Store"
        class="download-img-line-view-apple"
      />
      <div class="download-img-line-view-large-title">
        Download on
        <div class="download-img-line-view-large-text">APP Store</div>
      </div>
    </div>
		<div class="download-img-line-view-large" @click="downloadTF">
		  <img
		    src="@/assets/images/web/web_tf_logo.png"
		    alt="Apple Store"
		    class="download-img-line-view-apple"
				style="width: 35px;height: 35px;"
		  />
		  <div class="download-img-line-view-large-title">
		    Download on
		    <div class="download-img-line-view-large-text">TestFlight</div>
		  </div>
		</div>
    <div class="download-img-line-view-large">
      <img
        src="@/assets/images/web/web_header_google.png"
        alt="Google Play"
        class="download-img-line-view-google"
      />
      <div class="download-img-line-view-large-title">
        Coming Soon…
        <div class="download-img-line-view-large-text">Google Play</div>
      </div>
    </div>
    <div class="download-img-line-view-small" @click="downloadApk">
      <img src="@/assets/images/web/web_apk.png" alt="APK" class="download-img-line-view-apk" />
      <div class="download-img-line-view-large-title">
        Download the
        <div class="download-img-line-view-large-text">APK</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApk() {
      const a = document.createElement('a')
      a.href = 'https://www.letspay.cc/AppForAPK'
      a.target = '_blank'
      a.click()
    },
    downloadAppleStore() {
      const a = document.createElement('a')
      a.href = 'https://www.letspay.cc/AppForIOS'
      a.target = '_blank'
      a.click()
    },
		downloadTF() {
		  const a = document.createElement('a')
		  a.href = 'https://www.letspay.cc/AppForTF'
		  a.target = '_blank'
		  a.click()
		},
  },
}
</script>

<style lang="scss" scoped>
.download {
  &-img-line {
    position: relative;
    margin-top: 23px;
    display: flex;
    z-index: 99;

    &-view {
      &-large {
        margin-right: 14px;
        width: 184px;
        height: 67px;
        display: flex;
        align-items: center;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
        border-radius: 13px;
        cursor: pointer;

        &-title {
          font-size: 12px;
          line-height: 17px;
          color: #fff;
        }

        &-text {
          margin-top: 3px;
          font-size: 18px;
          line-height: 25px;
          color: #fff;
          font-weight: bold;
        }
      }

      &-small {
        min-width: 135px;
        height: 67px;
        padding-right: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
        border-radius: 13px;
        cursor: pointer;
      }

      &-apple {
        margin-left: 22px;
        margin-right: 15px;
        width: 31px;
        height: 37px;
      }

      &-google {
        margin-left: 22px;
        margin-right: 8px;
        width: 35px;
        height: 40px;
      }

      &-apk {
        margin-left: 22px;
        margin-right: 14px;
        width: 27px;
        height: 32px;
      }
    }
  }
}
</style>
>
