<template>
  <div class="mobile-plan anchor" id="PromotionRights">
    <div class="mobile-plan-outview">
      <div class="mobile-plan-top">
        <img src="@/assets/images/web/web_plan_img_1.png" class="mobile-plan-img" />
        <div>
          <div class="mobile-plan-top-title">{{ info.title }}</div>
          <div class="mobile-plan-top-line" />
          <div class="mobile-plan-top-sub-title">
            {{ info.subTitle }}
          </div>
        </div>
      </div>

      <div class="mobile-plan-content">
        <div>
          <img src="@/assets/images/web/web_plan_icon1_1.png" />
          <span>{{ info.list[0] }}</span>
        </div>

        <div>
          <img src="@/assets/images/web/web_plan_icon2_1.png" />
          <span>{{ info.list[1] }}</span>
        </div>

        <div>
          <img src="@/assets/images/web/web_plan_icon3_1.png" />
          <span>{{ info.list[2] }}</span>
        </div>

        <div>
          <img src="@/assets/images/web/web_plan_icon4_1.png" />
          <span>{{ info.list[3] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobilePlan',
  props: ['lang', 'info'],
}
</script>

<style lang="scss" scoped>
.mobile-plan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;

  &-outview {
    display: flex;
    flex-direction: column;
  }

  &-top {
    margin-bottom: 11px;
    display: flex;
    padding-right: 18px;
    padding-left: 36px;

    &-title {
      margin-top: 38px;
      font-size: 20px;
      font-weight: 800;
      color: #1acbb3;
      line-height: 28px;
    }

    &-line {
      margin-bottom: 18px;
      width: 82px;
      height: 4px;
      background: linear-gradient(159deg, #258cfb 0%, #1acbb3 100%);
    }

    &-sub-title {
      font-size: 12px;
      font-weight: 400;
      color: #000000;
      line-height: 23px;
    }
  }

  &-img {
    margin-top: 40px;
    margin-right: -10px;
    margin-left: -36px;
    width: 207px;
    height: 177px;
  }

  &-content {
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-top: 39px;
      width: 50vw;
      padding-left: 26px;
      padding-right: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > img {
        margin-bottom: 16px;
        width: 28px;
        height: 28px;
      }

      & > span {
        font-size: 12px;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
      }
    }
  }

  &-tip {
    margin-top: 33px;
    margin-left: 24px;
    margin-right: 24px;
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    line-height: 23px;
  }

  &-warn {
    margin-bottom: 40px;
    margin-top: 30px;
    margin-left: 18px;
    margin-right: 18px;
    font-size: 10px;
    font-weight: bold;
    color: #b7b7b7;
    line-height: 23px;
  }
}
</style>
